import React from 'react'
import {
    Box, FormControl, FormLabel, Input, Select, Stack,CheckboxGroup,Checkbox,Button
} from '@chakra-ui/react';
export default function AttributeMaster({spacing,register,attributes}) {
    return (
        <>
            <Box>
                <Stack as="fieldset" spacing={spacing} >
                    {attributes && attributes.map(item => {
                        return (
                            <FormControl key={item.ID}>
                                <FormLabel mt={2} fontWeight="semibold" as="legend" mb="0">
                                    {item.Name}
                                </FormLabel>
                                {item.Type == 1 ? <Input type="text" {...register(`attribute[Attr${item.ID}][]`)} /> : ""}
                                {item.Type == 3 ?
                                    <>
                                    {/* onChange={(e)=>handleSubmitFilter(e,"A")} */}
                                    <CheckboxGroup >
                                        {Object.values(item.Masters).map((master, ind1) => (
                                            <>
                                            <Checkbox minW={100}   value={`chk_${item.ID}_${Object.values(master)[1]}`} {...register(`attribute[Attr${item.ID}][]`)}  key={Object.values(master)[1]}  colorScheme="blue">
                                                <span>{Object.values(master)[0]}</span>
                                            </Checkbox></>
                                        ))}
                                    </CheckboxGroup>
                                    {/* <Select placeholder='Select' {...register(`attribute[Attr${item.ID}][]`)}>
                                        {Object.values(item.Masters).map((master, ind1) => {
                                            return (<option key={ind1} value={Object.values(master)[1]}>{Object.values(master)[0]}</option>);
                                        })}
                                    </Select> */}
                                    </>
                                    : ""}
                            </FormControl>
                        )
                    })}
                </Stack>
            </Box>
        </>
    )
}
