import React,{ useEffect } from 'react'
import {
    Heading,
    Text,
    Container,
    VStack,
  } from '@chakra-ui/react';
import DynamicButton from './DynamicButton'
import { useSelector,useDispatch } from 'react-redux'
import { Header } from '../header/Header';
import Footer from '../Footer/Footer';
import {changeCategoryLabel} from '../redux/slices/categorySlice';

const ShippingPolicy = () => {
  document.title = 'Home/Shipping policy';
  const dispatch = useDispatch();
  const companysetting  = useSelector((state) => state.company);
  useEffect(()=>{
    window.scrollTo(0, 0);
    dispatch(changeCategoryLabel(""));
    const spinner = document.getElementById("spinner");
    if(spinner){
      setTimeout(() => {
        spinner.style.display = "none";
      }, 1500);
    }
  },['']);
  return (
    <>
        <Header/>
        <Container maxW={'7xl'} p="12">
            <DynamicButton/>
            <Heading as="h1">Shipping policy</Heading>
            <VStack paddingTop="40px" spacing="2" alignItems="flex-start">
                <Text as="p" fontSize="lg">
                    At {companysetting.length > 0 && companysetting[0].DATA.company.Companyinfo.company_name}, we are committed to providing timely and reliable shipping services to our customers. This Shipping Policy outlines important information regarding our shipping methods, delivery timelines, and associated terms and conditions.
                </Text>
                <Heading fontSize="xl">1. Shipping Methods</Heading>  
                <Text as="p" fontSize="lg">
                    1.1 Domestic Shipping:
                </Text>
                <Text as="p" fontSize="lg">
                    We offer domestic shipping within India. We partner with reputable shipping carriers to ensure your order is delivered safely and efficiently. The shipping method may vary depending on the size, weight, and destination of the package.
                </Text>
                <Text as="p" fontSize="lg">
                    1.2 International Shipping:
                </Text>
                <Text as="p" fontSize="lg">
                    For customers outside India, we offer international shipping to select destinations. The availability of international shipping and associated costs will be displayed during the checkout process. Please note that additional customs fees, taxes, and import duties may apply, which are the responsibility of the customer.
                </Text>

                <Heading fontSize="xl">2. Delivery Timelines</Heading>
                <Text as="p" fontSize="lg">
                    2.1 Estimated Delivery Time:
                </Text>
                <Text as="p" fontSize="lg">
                    We strive to process and ship orders as quickly as possible. The estimated delivery time for your order depends on various factors, including the shipping destination, shipping method selected, and the availability of the product. Please note that these delivery times are estimates and not guarantees.
                </Text>
                <Text as="p" fontSize="lg">
                    2.2 Minimum Delivery Timeline: 2 Days
                </Text>
                <Text as="p" fontSize="lg">
                    For domestic orders, the minimum delivery timeline is typically up to 2 business days from the date of shipment. However, certain remote locations or circumstances beyond our control may result in longer delivery times.
                </Text>
                <Text as="p" fontSize="lg">
                    2.3 Maximum Delivery Timeline: 10 Days
                </Text>
                <Heading fontSize="xl">3. Data Security</Heading>
                <Text as="p" fontSize="lg">
                    For domestic orders, the maximum delivery timeline is typically up to 10 business days from the date of shipment. In most cases, you can expect to receive your order within this timeframe. However, please note that unforeseen delays, such as customs clearance or natural disasters, may occur and are beyond our control.
                </Text>
                <Text as="p" fontSize="lg">
                    2.4 International Delivery Timelines:
                </Text>
                <Text as="p" fontSize="lg">
                    For international orders, the delivery timeline may vary significantly depending on the shipping destination, customs clearance procedures, and local postal services. Please refer to the estimated delivery time provided during the checkout process for international orders.
                </Text>
                
                <Heading fontSize="xl">Tracking Orders</Heading>
                <Text as="p" fontSize="lg">
                    Once your order has been shipped, we will provide you with a tracking number and instructions on how to track your package. You can track the progress of your shipment through our website or by using the tracking information provided by the shipping carrier.
                </Text>
                <Heading fontSize="xl">4.Order Processing Time</Heading>
                <Text as="p" fontSize="lg">
                    4.1 Order Processing:
                </Text>
                <Text as="p" fontSize="lg">
                    Order processing typically takes 30 business days. During this time, we verify the order, package the items, and prepare them for shipment. Please note that order processing time is separate from the estimated delivery time.
                </Text>
                <Text as="p" fontSize="lg">
                    4.2 Delayed Order Processing:
                </Text>
                <Text as="p" fontSize="lg">
                    In rare cases, order processing may be delayed due to factors such as product availability, high order volumes, or unforeseen circumstances. If there is a significant delay in processing your order, our customer support team will notify you promptly and provide updates on the status of your shipment.
                </Text>
                <Heading fontSize="xl">5. Address Accuracy</Heading>
                <Text as="p" fontSize="lg">
                    Please ensure that you provide accurate and complete shipping address details during the checkout process. We will not be held responsible for any delays or non-delivery of orders resulting from incorrect or incomplete address information provided by the customer.
                </Text>

                <Heading fontSize="xl">6.Contact Us</Heading>
                <Text as="p" fontSize="lg">
                    If you have any questions or concerns regarding our Shipping Policy, please feel free to contact our customer support team. We will be happy to assist you.
                </Text>
                <Text as="p" fontSize="lg">
                    Note: This Shipping Policy is subject to change without prior notice. Please refer to the latest version available on our website for the most up-to-date information.
                </Text>
            </VStack>
        </Container>
        <Footer/>
    </>
  )
}

export default ShippingPolicy