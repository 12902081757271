import React,{ useEffect } from 'react'
import {
    Heading,
    Text,
    Container,
    VStack,
  } from '@chakra-ui/react';
import DynamicButton from './DynamicButton'
import { useSelector,useDispatch } from 'react-redux'
import { Header } from '../header/Header';
import Footer from '../Footer/Footer';
import {changeCategoryLabel} from '../redux/slices/categorySlice';

const PrivacyPolicy = () => {
  document.title = 'Home/Privacy Policy';
  const dispatch = useDispatch();
  const companysetting  = useSelector((state) => state.company);
  useEffect(()=>{
    window.scrollTo(0, 0);
    dispatch(changeCategoryLabel(""));
    const spinner = document.getElementById("spinner");
    if(spinner){
      setTimeout(() => {
        spinner.style.display = "none";
      }, 1500);
    }
  },['']);
  return (
    <>
        <Header/>
        <Container maxW={'7xl'} p="12">
            <DynamicButton/>
            <Heading as="h1">Privacy Policy</Heading>
            <VStack paddingTop="40px" spacing="2" alignItems="flex-start">
                <Text as="p" fontSize="lg">
                  This Privacy Policy describes how {companysetting.length > 0 && companysetting[0].DATA.company.Companyinfo.company_name} collects, uses, and protects the personal information you provide when using our website. We are committed to safeguarding your privacy and ensuring that your personal information is handled securely and in accordance with applicable data protection laws. By using our website, you consent to the collection, use, and disclosure of your personal information as described in this Privacy Policy.
                </Text>
                <Heading fontSize="xl">1. Information We Collect</Heading>  
                <Text as="p" fontSize="lg">
                    1.1. Personal Information: When you create an account, place an order, or engage in other activities on our website, we may collect personal information such as your name, email address, shipping address, phone number, and payment details.
                </Text>
                <Text as="p" fontSize="lg">
                    1.2. Cookies and Tracking Technologies: We may use cookies and other tracking technologies to collect information about your browsing behavior on our website. This may include your IP address, browser type, operating system, referral source, and other browsing details.
                </Text>
                <Text as="p" fontSize="lg">
                    1.3. Log Files: Our web servers may automatically record certain information when you access or use our website. This may include your IP address, browser type, referring/exit pages, and other usage information.
                </Text>
                <Heading fontSize="xl">2. Use of Information</Heading>
                <Text as="p" fontSize="lg">
                    2.1. Personalization: We use your personal information to personalize your experience, improve customer service, and tailor our offerings to your specific needs.
                </Text>
                <Text as="p" fontSize="lg">
                    2.2. Order Processing: We use your personal information to process and fulfill your orders, including order confirmation, shipment tracking, and customer support.
                </Text>
                <Text as="p" fontSize="lg">
                    2.3. Communication: We may use your contact information to send you transactional emails, respond to your inquiries, and provide updates about your orders or our products and services.
                </Text>
                <Text as="p" fontSize="lg">
                    2.4. Marketing: With your consent, we may use your personal information to send you promotional emails, newsletters, and offers. You can opt-out of marketing communications at any time.
                </Text>
                <Text as="p" fontSize="lg">
                    2.5. Analytics: We may use aggregated and anonymized data for analytical purposes, such as analyzing website usage patterns, monitoring trends, and improving our website's performance.
                </Text>

                <Heading fontSize="xl">3. Data Security</Heading>
                <Text as="p" fontSize="lg">
                    3.1. Security Measures: We implement appropriate security measures to protect your personal information from unauthorized access, alteration, disclosure, or destruction. These measures may include encryption, secure browsing, and restricted access to personal data.
                </Text>
                <Text as="p" fontSize="lg">
                  3.2. Payment Security: We use reputable payment service providers to process your payment information securely. We do not store your payment card details on our servers.
                </Text>

                <Heading fontSize="xl">4. Data Sharing and Disclosure</Heading>
                <Text as="p" fontSize="lg">
                    4.1. Third-Party Service Providers: We may share your personal information with trusted third-party service providers who assist us in operating our website, processing payments, delivering orders, or providing other services. These providers have access to personal information only to the extent necessary to perform their tasks and are obligated to maintain its confidentiality.
                </Text>
                <Text as="p" fontSize="lg">
                    4.2. Legal Requirements: We may disclose your personal information if required by law, regulation, legal process, or governmental request.
                </Text>

                <Heading fontSize="xl">5. Your Rights and Choices</Heading>
                <Text as="p" fontSize="lg">
                    5.1. Access and Update: You may access and update your personal information by logging into your account on our website or by contacting our customer support team.
                </Text>
                <Text as="p" fontSize="lg">
                    5.2. Opt-Out: You can unsubscribe from our marketing communications at any time by following the unsubscribe instructions provided in the emails or by contacting us directly.
                </Text>
                <Text as="p" fontSize="lg">
                    5.3. Cookies: You can set your browser to refuse cookies or alert you when cookies are being sent. However, certain features of our website may not function properly without cookies.
                </Text>
            </VStack>
        </Container>
        <Footer/>
    </>
  )
}

export default PrivacyPolicy