import {
  Button,
  Flex,
  Heading,
  Link,
  Stack,
  Text,
  useColorModeValue as mode,
} from '@chakra-ui/react';
import React,{useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import { FaArrowRight } from 'react-icons/fa';
import { formatPrice } from './PriceTag';
import { useDispatch,useSelector } from 'react-redux';
import { removeAllItem } from '../../redux/slices/cartSlice';
import axios from 'axios';
import swal from 'sweetalert';
import http from "../../http-common";
import CryptoJS from 'crypto-js';

const OrderSummaryItem = (props) => {
  const { label, value, children } = props;
  return (
    <Flex justify="space-between" fontSize="sm">
      <Text fontWeight="medium" color={mode('gray.600', 'gray.400')}>
        {label}
      </Text>
      {value ? <Text fontWeight="medium">{value}</Text> : children}
    </Flex>
  )
}
export const CartOrderSummary = ({cart_array,trans_type}) => {
  //const cart = useSelector((state) => state.cart.cart);
  const [disablebtn, setDisableBtn] = React.useState(true);
  const [razorpaykey,setRazorPayKey] =  React.useState("rzp_test_JtGKkR6wQbxWNM");
  const [razorpaysecretkey,setRazorPaySecretKey] =  React.useState("quGCueDYOXTU9x97Uh2pCDKg");
  const AUTHORIZE_KEY = process.env.REACT_APP_AUTHORIZE_KEY;
  const base_url = useSelector(state => state.baseurl.baseURL);; //window.location.origin;  //"https://ecommerce.ondemandcrm.co";  //"https://gadgets.ondemandcrm.co"; 
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const companysetting  = useSelector((state) => state.company);
  const company_name = companysetting.length > 0 ? companysetting[0].DATA.company.Companyinfo.company_name : "Horus tech solution";
  
  useEffect(()=>{
    if(cart_array.length > 0){
      setDisableBtn(false);
    }else{
      setDisableBtn(true);
    }
  },[cart_array])
  const getTotal = () => {
    let totalQuantity = 0;
    let totalPrice = 0;
    let totaltax = 0;
    let grandtotal = 0;
    let chk_type = 0;
    let chk_transaction_type = 0;
    let tot_shipping_charge = 0;
    cart_array.forEach(item => {
      totalQuantity += item.quantity;
      totalPrice += (item.price * item.quantity);
      totaltax += item.TotalTax;
      grandtotal +=  item.Total;
      tot_shipping_charge += item.Shipping;
      chk_type = (item.invoice_type > 0) ? 0 : 1;
      chk_transaction_type = item.transaction_type;
      
    })
    return { totalPrice, totalQuantity,totaltax,grandtotal,chk_type,chk_transaction_type,tot_shipping_charge }
  }
  const priceVal = getTotal().totalPrice;
  const priceTotTax = getTotal().totaltax;
  const priceGrandTot = getTotal().grandtotal;
  const checking_type = getTotal().chk_type;
  const checking_transaction_type = getTotal().chk_transaction_type;
  const price_ship_charge = getTotal().tot_shipping_charge;

  const createInvoice = (mode) =>{
    http.post(`/ecommerce_api/saveEcommerceInvoice/`, {
        contact_id: localStorage.getItem("contact_id"),
        cart_id:mode
    }).then(res => {
        if (res.data[0].STATUS == "SUCCESS") {
          swal("Success ", res.data[0].MSG, "success");
          dispatch(removeAllItem());
          {/* /ecommerce Remove  */}
          navigate("/");
          setDisableBtn(false);
        } else {
          setDisableBtn(false);
        }
    }).catch(err => {
        swal("Something went wrong", "error");
        setDisableBtn(false);
    });
  }
  const paymentdone = (id, response, mode) => {
      async function updatetable() {
          let url = `${base_url}/expo_access_api/updateRazorpay/`;
          const res = await axios.post(url, {
              AUTHORIZEKEY: AUTHORIZE_KEY,
              response: response,
              id: id
          });
          if (res) {
              if (res.data[0].STATUS == "SUCCESS") {
                  console.log("payment success response");console.log(response);
                  if (response.razorpay_payment_id) {
                      createInvoice(mode);
                  }else{
                      setDisableBtn(false);
                  }
                  
              }
          }
        }
        updatetable();
  }

  const openCheckout = (receipt_id, order_id, id, amount, currency, name, number, email, mode) => {
    if (order_id) {
        let options = {
            "key": razorpaykey,
            "amount": amount, // 2000 paise = INR 20, amount in paisa
            "name": company_name,//user_details.company_name,
            "description": "",
            "currency": "INR",
            "image": `${base_url}/viewimage/getcompany`,
            //"order_id": order_id,
            "handler": function (response) {
                const generated_signature = CryptoJS.HmacSHA256(order_id + "|" + response.razorpay_payment_id, razorpaysecretkey).toString();
                console.log("PaymentRes");
                console.log(generated_signature);
                console.log(response.razorpay_signature);
                //remember uncomment this on live
                //if (generated_signature == response.razorpay_signature) {
                    paymentdone(id, response, mode);
                    //alert("Payment done successfully");
                    //dispatch(ToastNotify(true, "SUCCESS", "Payment done successfully", "success"));
                //}
            },
            "prefill": {
                "name": name ? name : "",
                "email": email ? email : "",
                "contact": number ? number : ""
            },
            "notes": {
                "receiptid": receipt_id,
                "paymentFor": mode
            },
            "theme": {
                "color": "#1b1a49"
            },
            "config": {
              "display": {
                "blocks": {
                  "banks": {
                    "name": 'Methods with Offers',
                    "instruments": [
                      {
                          "method": 'upi'
                      },
                      ],
                  },
                },
                "hide": [
                  // {
                  // "method": "card"
                  // },
                  // {
                  //   "method": "netbanking"
                  // },
                  // {
                  //   "method": "wallet"
                  // }
                ],
                "sequence": ['block.banks'],
                "preferences": {
                  "show_default_blocks": true,
                },
              },
            },
        };
        let rzp = new window.Razorpay(options);
        rzp.open();
        rzp.on('payment.failed', function (response) {
            paymentdone(id, response, mode);
            //alert("payment not done successfully please try again");
            // toast.error('payment not done successfully please try again', {
            //   position: "top-right",
            //   autoClose: 5000,
            //   hideProgressBar: false,
            //   closeOnClick: true,
            //   pauseOnHover: true,
            //   draggable: true,
            //   progress: undefined,
            // });
        });
    }

}

  const GenerateOrderId = (amount, currency, contactid, contacttype, name, number, email, mode) => {
    async function getorderId() {
        let url = `${base_url}/expo_access_api/razorpayOrderId/`;
        const res = await axios.post(url, {
            AUTHORIZEKEY: AUTHORIZE_KEY,
            amount: amount * 100,
            currency: currency,
            contact: contactid,
            type: contacttype,
            mode: mode
        });
        if (res) {
            if (res.data[0].STATUS == "SUCCESS") {
                openCheckout(res.data[0].DATA.receipt_id, res.data[0].DATA.order_id, res.data[0].DATA.id, (amount * 100), currency, name, number, email, mode);
            }
        }
    }
    getorderId();
  }

  const handleEcommerceData = (e) =>{
    if(checking_type > 0 && checking_transaction_type == 22){
      alert("Invoice type is not defined please contact your Administrator")
      return false;
    }
    setDisableBtn(true);
    http.post(`/ecommerce_api/saveEcommerceData/`, {
        contact_id: localStorage.getItem("contact_id"),
        products:JSON.stringify(cart_array)
    }).then(res => {
        if (res.data[0].STATUS == "SUCCESS") {
          console.log("RES");console.log(res.data[0].IS_INVOICE);
          if(res.data[0].IS_INVOICE > 0){
            //alert("Invoice"+res.data[0].IS_INVOICE);
            GenerateOrderId((priceGrandTot+price_ship_charge),"INR",102,1,res.data[0].CONTACT_NAME,res.data[0].CONTACT_MOBILE,res.data[0].CONTACT_EMAIL,"Order-"+res.data[0].IS_INVOICE);
          }else{
            swal("Success ", res.data[0].MSG, "success");
            dispatch(removeAllItem());
            {/* /ecommerce Remove  */}
            navigate("/");
            setDisableBtn(false);
          }
        } else {
          swal(res.data[0].MSG);
          setDisableBtn(false);
        }
    }).catch(err => {
        swal("Something went wrong", "error");
        setDisableBtn(false);
    });
  }

  return (
    <Stack spacing="8" borderWidth="1px" rounded="lg" padding="8" width="full">
      <Heading size="md">Summary</Heading>

      <Stack spacing="6">
        <OrderSummaryItem label="Subtotal" value={formatPrice(priceVal)} />
        {trans_type == 22 &&
          <OrderSummaryItem label="Total Tax">
            <Link href="#" textDecor="underline">
              {formatPrice(priceTotTax)}
            </Link>
          </OrderSummaryItem>
        }
        <OrderSummaryItem label="Shipping Charges">
          <Link href="#" textDecor="underline">
            {price_ship_charge > 0 ? formatPrice(price_ship_charge) : 0}
          </Link>
        </OrderSummaryItem>
        <Flex justify="space-between">
          <Text fontSize="lg" fontWeight="semibold">
            Total
          </Text>
          <Text fontSize="xl" fontWeight="extrabold">
            {trans_type == 22 ? formatPrice(priceGrandTot + price_ship_charge) : formatPrice(priceVal)}
          </Text>
        </Flex>
      </Stack>
      <Button colorScheme="green" size="md" borderRadius={0} fontSize="md" rightIcon={<FaArrowRight />} 
      onClick={handleEcommerceData}
       disabled={disablebtn}>
      {(companysetting.length > 0 && companysetting[0].DATA.label_checkout != null) ? companysetting[0].DATA.label_checkout : "Checkout"}
      </Button>
    </Stack>
  )
}
