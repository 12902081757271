import React, { useState, useEffect } from 'react';
import {
    Button, useDisclosure, Stack,
    useColorMode,
    HStack,
    Text,
    Image,
    Input,
    Center, Select,
    Wrap, WrapItem, PinInput, PinInputField, Heading, FormControl, FormLabel,useToast
} from '@chakra-ui/react';
import { AiOutlineCaretLeft } from 'react-icons/ai';
import swal from 'sweetalert';
import { useForm } from "react-hook-form";
import http from "../http-common";
import {changeAuthLabel,transferDummyCartToMainCarts,changePincode,changeDeliveyStatus} from '../redux/slices/cartSlice';
import { useDispatch,useSelector } from 'react-redux';

export default function Login({ onClose,address }) {
    const [showMobile, setShowMobile] = useState(true);
    const [showOtp, setShowOtp] = useState(false);
    const [showContact, setShowContact] = useState(false);
    const [mobileno, setMobileNo] = useState("");
    const [error, setError] = useState({ mobileerror: "", otperror: "" });
    const [addcontact, setAddContact] = useState({ name: "", mobile: "", email: "", address1: "", address2: "", country: "", state: "", city: "", zipcode: "", gstnumber: "" });
    const [otpkey, setOtpKey] = useState("");
    const [pinval, setPinVal] = useState("");
    const [country, setCountry] = useState([]);
    const [State, setState] = useState([]);
    const [selcountry, setSelCountry] = useState("");
    const [btndisable,setButtonDisable] = useState(false);
    const { register,setValue, formState: { errors }, handleSubmit } = useForm();
    const base_url = useSelector(state => state.baseurl.baseURL);;  //window.location.origin;  //"https://gadgets.ondemandcrm.co"; 
    const dispatch = useDispatch();
    const toast = useToast();
    const dummycarts = useSelector((state) => state.cart.dummycarts);
    
    const onSubmit = data =>{
        setButtonDisable(true);
        http.post(`/ecommerce_api/saveContact/`, {
            contact: data,
        }).then(res => {
            if (res.data[0].STATUS == "SUCCESS") {
                if(res.data[0].DATA > 0){
                    localStorage.setItem("phptoken",1);
                    localStorage.setItem("contact_id",res.data[0].DATA);
                    dispatch(changeAuthLabel("Sign out"));
                    console.log(dummycarts.length);
                    if(dummycarts.length > 0){
                        dispatch(transferDummyCartToMainCarts(""));
                        toast({
                            title: 'Product added to the cart',
                            description: "It has been added to the cart successfully.",
                            position: 'top-left',
                            status: 'success',
                            duration: 5000,
                            isClosable: true,
                          });
                    }
                    setButtonDisable(false);
                    onClose();
                }else{
                    swal(res.data[0].MSG);
                    dispatch(changeAuthLabel("Sign in"));
                    setButtonDisable(false);
                }
                //setSelProducts(res.data[0].DATA.products);
                //reset({ ...data });
                //onClose();
            } else {
                //setSelProducts([]);
                //reset({ ...data });
                //onClose();
                setButtonDisable(false);
            }
        }).catch(err => {
            //console.log(err.message);
            //reset({ ...data })
            swal("Something went wrong", "error");
            setButtonDisable(false);
        });
    } 

    
    const sendMobileOtp = async () => {
        if (mobileno) {
            setShowMobile(false);
            setShowOtp(true);
            http.post(`/ecommerce_api/verifyGenerateOTP/`,{
              contact:mobileno
            })
            .then(res => {
                if (res.data[0].STATUS == "SUCCESS") {
                  setOtpKey(res.data[0].DATA);
                }else{
                  setOtpKey("");
                  setError({mobileerror:res.data[0].MSG});
                }
            })
            .catch(err => {
            });
            setError({ mobileerror: "" });
        } else {
            setError({ mobileerror: "Please enter mobile no first" });
        }
    }
    const handleVerifyMobileOtp = async () => {
        if (pinval) {
            http.post(`/ecommerce_api/verifyRegisterOTP/`,{
              contact:mobileno,
              key:otpkey,
              verifyOTP:pinval
            })
            .then(res => {
                if (res.data[0].STATUS == "SUCCESS") {
                    if(res.data[0].DATA > 0){
                        setShowMobile(false);
                        setShowOtp(false);
                        setError({otperror:""});

                        localStorage.setItem("phptoken",1);
                        localStorage.setItem("contact_id",res.data[0].Contact_id);
                        localStorage.setItem("pincodes",res.data[0].pincode);
                        localStorage.setItem("del_status",res.data[0].pickup_available);
                        
                        dispatch(changeAuthLabel("Sign out"));
                        dispatch(changePincode(res.data[0].pincode));
                        dispatch(changeDeliveyStatus(res.data[0].pickup_available));
                        
                        if(dummycarts.length > 0){
                            dispatch(transferDummyCartToMainCarts());
                            toast({
                                title: 'Product added to the cart',
                                description: "It has been added to the cart successfully.",
                                position: 'top-left',
                                status: 'success',
                                duration: 5000,
                                isClosable: true,
                            });
                        }
                        
                        onClose();      
                    }else{

                        setShowContact(true);
                        setShowMobile(false);
                        setShowOtp(false);
                        setError({otperror:""});
                        dispatch(changeAuthLabel("Sign in"));
                        localStorage.setItem("pincodes","");
                        localStorage.setItem("del_status","");

                    }
                }else{
                  setError({otperror:res.data[0].MSG});      
                }
            })
            .catch(err => {
            });

        } else {
            setError({ otperror: "Please enter otp first" });
        }
    }
    const getCountryList = e => {
        async function findCountryList() {
            let url = '/expo_access_api/getCountryList/';
            const res = await http.post(url, {
                AUTHORIZEKEY: "c34FxCQy4DCGCHA9zlTgqTsywZJSW8hy",
            });
            if (res.data[0].STATUS == "SUCCESS") {
                setCountry(res.data[0].DATA);
                setValue("country","India");
                changestate(e,"India");
            } else {
                console.log("undone")
            }
        }
        findCountryList();
    }
    const changestate = (e, val) => {
        var element_val = val == 0 ? e.target.value : val;
        setSelCountry(element_val);
        setAddContact({ ...addcontact, country: element_val });
        async function getStates() {
            const res = await http.post("/expo_access_api/getstatelist/", {
                AUTHORIZEKEY: "c34FxCQy4DCGCHA9zlTgqTsywZJSW8hy",
                COUNTRY: element_val,
            });
            if (res) {
                if (res.data[0].STATUS === "SUCCESS") {
                    if (res.data[0].DATA.state) {
                        setState(res.data[0].DATA.state);
                        setValue("state","GUJARAT");
                    }
                } else {
                    swal("Not Found", "State Not Found", "danger");
                    setState([]);
                }
            }
        }
        getStates();
    };
   
    useEffect(() => {
        getCountryList();
    }, []);
    return (
        <>
            {showOtp &&
                <Button
                    colorScheme='orange'
                    borderRadius={'60px'}
                    onClick={() => { setShowMobile(true); setShowOtp(false); setShowContact(false); setPinVal(""); }}
                > <AiOutlineCaretLeft /></Button>
            }

            {showMobile &&
                <>
                    <Stack
                        maxW="xs"
                        mx="auto"
                        py={{ base: '12', md: '16' }}
                        spacing={{ base: '6', md: '8' }}
                    >
                        <Stack spacing="3" textAlign="center">
                            <Image
                                boxSize='100px'
                                objectFit='cover'
                                src={`${base_url}/public/images/MedBot/mobile-img.svg`}
                                alt='Login'
                                ml={'110px'}
                            />
                            <Text fontSize="2xl">Mobile Verification</Text>
                            <Text fontSize="md">Please enter your 10 digit whatsapp mobile number.</Text>
                            <Stack spacing={3}>
                                <Input variant='flushed' minLength={10} maxLength={10} value={mobileno} onChange={(e) => { setMobileNo(e.target.value); setAddContact({ ...addcontact, mobile: e.target.value }); }} placeholder='Mobile Number' textAlign={'center'} onKeyPress={(event) => {
                                    if (!/^[+-]?\d*(?:[.,]\d*)?$/.test(event.key)) {
                                        event.preventDefault();
                                    }
                                }} />
                            </Stack>
                            <Text fontSize='md' color={'red.800'}>{error.mobileerror && error.mobileerror}</Text>
                        </Stack>
                        <Wrap spacing='30px' justify='center'>
                            <WrapItem>
                                <Center>
                                    <Button variant='outline' colorScheme='red' size='md' onClick={onClose}>
                                        Cancel
                                    </Button>
                                </Center>
                            </WrapItem>
                            <WrapItem>
                                <Center>
                                    <Button colorScheme='teal' size='md' onClick={sendMobileOtp}>
                                        Send OTP
                                    </Button>
                                </Center>
                            </WrapItem>
                        </Wrap>
                    </Stack>
                </>
            }
            {showOtp &&
                <>
                    <Stack
                        maxW="xs"
                        mx="auto"
                        py={{ base: '12', md: '16' }}
                        spacing={{ base: '6', md: '8' }}
                    >
                        <Stack spacing="2" textAlign="center">
                            <Image
                                boxSize='100px'
                                objectFit='cover'
                                src={`${base_url}/public/images/MedBot/otp-mobile.svg`}
                                alt='Login'
                                ml={'110px'}
                            />
                            <Text fontSize="2xl">Enter OTP</Text>
                            <Text fontSize="sm">Please enter OTP to confirm your Mobile Number.</Text>
                            <Text fontSize="sm">{mobileno}</Text>
                            <Text fontSize="lg">OTP</Text>

                            <Stack spacing={3} >
                                <HStack ml={'22px'}>
                                    <PinInput 
                                        value={pinval}
                                        onChange={(e) => setPinVal(e)}
                                        otp
                                    >
                                        <PinInputField />
                                        <PinInputField />
                                        <PinInputField />
                                        <PinInputField />
                                        <PinInputField />
                                        <PinInputField />
                                    </PinInput>
                                </HStack>
                                <Text fontSize='md' color={'red.800'}>{error.otperror && error.otperror}</Text>
                            </Stack>
                        </Stack>
                        <Wrap spacing='30px' justify='center'>
                            <WrapItem>
                                <Center>
                                    <Button variant='outline' colorScheme='gray' size='md' onClick={sendMobileOtp}>
                                        RESEND OTP
                                    </Button>
                                </Center>
                            </WrapItem>
                            <WrapItem>
                                <Center>
                                    <Button colorScheme='orange' size='md' onClick={handleVerifyMobileOtp}>
                                        VERIFY NOW
                                    </Button>
                                </Center>
                            </WrapItem>
                        </Wrap>
                    </Stack>
                </>
            }
            {showContact &&
                <>
                    <form onSubmit={handleSubmit(onSubmit)}>
                    <Stack
                        //maxW="xs"
                        mx="auto"
                        py={{ base: '6', md: '8' }}
                        spacing={{ base: '6', md: '6' }}
                    >
                        <Heading
                            as={'h5'}
                            fontSize={{ base: 'lg', sm: 'xl' }}
                        //textAlign={'center'}
                        //mb={2}
                        >
                            Please Enter Following Details
                        </Heading>
                        <Stack
                            spacing="6"
                            direction={{
                                base: 'column',
                                md: 'row',
                            }}
                        >
                            <FormControl id="name" isRequired>
                                <FormLabel ><b>Name</b></FormLabel>
                                <Input placeholder='Name'  bg={'gray.100'} {...register("name", { required: true, maxLength: 30 })} />
                            </FormControl>
                            <FormControl id="email">
                                <FormLabel><b>Email Address</b></FormLabel>
                                <Input type='email' placeholder='Email Address'  bg={'gray.100'} {...register("email")} />
                                <Input type='hidden'   bg={'gray.100'} {...register("mobile")} value={mobileno}/>
                            </FormControl>
                        </Stack>
                        <Stack
                            spacing="6"
                            direction={{
                                base: 'column',
                                md: 'row',
                            }}
                        >
                            <FormControl id="address1" isRequired>
                                <FormLabel ><b>Addrss Line 1</b></FormLabel>
                                <Input placeholder='Addrss Line 1' bg={'gray.100'}  {...register("address1", { required: true })} />
                            </FormControl>
                            <FormControl id="address2">
                                <FormLabel ><b>Addrss Line 2</b></FormLabel>
                                <Input placeholder='Addrss Line 2' bg={'gray.100'}  {...register("address2")} />
                            </FormControl>
                        </Stack>
                        <Stack
                            spacing="6"
                            direction={{
                                base: 'column',
                                md: 'row',
                            }}
                        >
                            <FormControl id="country" isRequired>
                                <FormLabel ><b>Country</b></FormLabel>
                                <Select placeholder='Select Country'
                                    size='md'
                                    bg={'gray.100'}
                                    {...register("country", { required: true })}
                                    onChange={(e) => { changestate(e, 0) }}
                                >
                                    {country.map((cntry) => (
                                        <option key={cntry.Country.country_code} value={cntry.Country.country_name}>
                                            {cntry.Country.country_name}
                                        </option>
                                    ))}
                                </Select>
                            </FormControl>
                            <FormControl id="state" isRequired>
                                <FormLabel><b>State</b></FormLabel>
                                <Select
                                    placeholder='Select State'
                                    size='md'
                                    bg={'gray.100'}
                                    {...register("state", { required: true })}
                                    onChange={(e) => { setAddContact({ ...addcontact, state: e.target.value }) }}
                                >
                                    {State
                                        ?
                                        [State.map((S) => (
                                            <option key={`state_${S.State.state_name}`} value={S.State.state_name}>{S.State.state_name}</option>
                                        ))]
                                        : ""
                                    }
                                </Select>
                            </FormControl>
                            <FormControl id="city" isRequired>
                                <FormLabel><b>City</b></FormLabel>
                                <Input placeholder='City' bg={'gray.100'} {...register("city", { required: true })} />
                            </FormControl>
                        </Stack>
                        <Stack
                            spacing="6"
                            direction={{
                                base: 'column',
                                md: 'row',
                            }}
                        >
                            <FormControl id="zipcode" isRequired>
                                <FormLabel ><b>Zipcode</b></FormLabel>
                                <Input placeholder='Zipcode' bg={'gray.100'} {...register("zipcode")}   onKeyPress={(event) => {
                                    if (!/^[+-]?\d*(?:[.,]\d*)?$/.test(event.key)) {
                                        event.preventDefault();
                                    }
                                }} />
                            </FormControl>
                            <FormControl id="gstnumber">
                                <FormLabel><b>Gst Number</b></FormLabel>
                                <Input placeholder='Gst Number' bg={'gray.100'} {...register("gstnumber")}  />
                            </FormControl>
                        </Stack>
                        <Wrap spacing='30px' justify='center'>
                            <WrapItem>
                                <Center>
                                    <Button variant='outline' colorScheme='gray' size='md' onClick={onClose}>
                                        Cancel
                                    </Button>
                                </Center>
                            </WrapItem>
                            <WrapItem>
                                <Center>
                                    <Button colorScheme='teal' size='md' type="submit" disabled={btndisable}>
                                        Done
                                    </Button>
                                </Center>
                            </WrapItem>
                        </Wrap>
                    </Stack>
                    </form>
                </>
            }
        </>
    )
}
