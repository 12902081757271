import React,{ useEffect } from 'react'
import {
    Heading,
    Text,
    Container,
    VStack,
  } from '@chakra-ui/react';
import DynamicButton from './DynamicButton'
import { useSelector,useDispatch } from 'react-redux'
import { Header } from '../header/Header';
import Footer from '../Footer/Footer';
import {changeCategoryLabel} from '../redux/slices/categorySlice';

const RefundPolicy = () => {
    document.title = 'Home/Refund and Cancellation Policy';
    const dispatch = useDispatch();
    const companysetting  = useSelector((state) => state.company);
    useEffect(()=>{
      window.scrollTo(0, 0);
      dispatch(changeCategoryLabel(""));
      const spinner = document.getElementById("spinner");
      if(spinner){
        setTimeout(() => {
          spinner.style.display = "none";
        }, 1500);
      }
    },['']);
  return (
    <>
        <Header/>
        <Container maxW={'7xl'} p="12">
            <DynamicButton/>
            <Heading as="h1">Refund and Cancellation Policy</Heading>
            <VStack paddingTop="40px" spacing="2" alignItems="flex-start">
                <Text as="p" fontSize="lg">
                    At {companysetting.length > 0 && companysetting[0].DATA.company.Companyinfo.company_name}, we strive to provide you with the best online shopping experience. We understand that sometimes you may need to cancel an order or return a product, and we want to make the process as convenient as possible. This Refund and Cancellation Policy outlines our procedures for cancellations, returns, and refunds.
                </Text>
                <Heading fontSize="xl">1. Cancellation Policy</Heading>  
                <Text as="p" fontSize="lg">
                    1.1 Order Cancellation by Customers:
                </Text>
                <Text as="p" fontSize="lg">
                    You may cancel your order within 2 hours of placing it, provided it has not been shipped or processed. To cancel an order, please contact our customer support team immediately. We will make every effort to accommodate your request, but please note that order cancellations are subject to review and may not be possible if the order is already in the processing or shipping stage.
                </Text>
                <Text as="p" fontSize="lg">
                    1.2 Order Cancellation by {companysetting.length > 0 && companysetting[0].DATA.company.Companyinfo.company_name}:
                </Text>
                <Text as="p" fontSize="lg">
                    In rare cases, we reserve the right to cancel an order due to reasons such as product unavailability, technical issues, or suspicion of fraudulent activity. If we cancel your order, we will notify you promptly and provide a full refund.
                </Text>
                <Heading fontSize="xl">2. Return Policy</Heading> 
                <Text as="p" fontSize="lg">
                    2.1 Return Eligibility:
                </Text>
                <Text as="p" fontSize="lg">
                    We accept returns within 5 days from the date of delivery. To be eligible for a return, the product must be unused, in its original packaging, and in the same condition as when you received it. Some products may have specific return restrictions, such as perishable items, personalized products, or items categorized as non-returnable due to hygiene or safety reasons.
                </Text>
                <Text as="p" fontSize="lg">
                    2.2 Return Process:
                </Text>
                <Text as="p" fontSize="lg">
                    To initiate a return, please contact our customer support team within the specified return period. Our team will guide you through the return process and provide you with a return authorization, if applicable. You may be required to provide relevant details, such as order number, product details, and reason for return. Please ensure that the product is securely packaged to prevent any damage during transit.
                </Text>
                <Text as="p" fontSize="lg">
                    2.3 Return Shipping:
                </Text>
                <Text as="p" fontSize="lg">
                    Unless the return is due to an error on our part, you will be responsible for the return shipping costs. We recommend using a trackable shipping method to ensure the safe delivery of the returned product. Upon receiving the returned item, we will inspect it to ensure it meets the return eligibility criteria mentioned in Section 2.1.
                </Text>
                <Text as="p" fontSize="lg">
                    2.4 Refund Process:
                </Text>
                <Text as="p" fontSize="lg">
                    Once the returned product has been inspected and approved, we will initiate the refund process. The refund will be issued using the original payment method used during the purchase. Please note that it may take 10 business days for the refund to reflect in your account, depending on your bank or payment provider.
                </Text>
                <Heading fontSize="xl">3. Exceptions and Additional Information</Heading>

                <Text as="p" fontSize="lg">
                    3.1 Exchange Policy:
                </Text>
                <Text as="p" fontSize="lg">
                    At this time, we do not offer direct product exchanges. If you wish to exchange a product, please initiate a return as per our return policy and place a new order for the desired item.
                </Text>
                <Text as="p" fontSize="lg">
                    3.2 Damaged or Defective Products:
                </Text>
                <Text as="p" fontSize="lg">
                    If you receive a damaged or defective product, please contact our customer support team immediately. We will guide you through the necessary steps to resolve the issue, which may include providing a replacement or issuing a refund.
                </Text>
                <Text as="p" fontSize="lg">
                    3.3 Non-Refundable Items:
                </Text>
                <Text as="p" fontSize="lg">
                    Certain items may be non-refundable, including but not limited to gift cards, downloadable software, and digital products. Any such exceptions will be clearly mentioned on the product page.
                </Text>
            </VStack>
        </Container>
        <Footer/>
    </>
  )
}

export default RefundPolicy