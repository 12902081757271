import React from 'react';
import { Card, CardBody,Text, Center,Heading,Button } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { removeAllItem,changeAuthLabel,removeAllDummyCart,changePincode,changeDeliveyStatus } from '../redux/slices/cartSlice';
import { useDispatch, useSelector } from 'react-redux';

export default function Logout({onClose}) {
    let navigate = useNavigate();
    const dispatch = useDispatch();
    const cart = useSelector((state) => state.cart.cart);
    const handleLogout = () =>{
        localStorage.setItem("phptoken","");
        localStorage.setItem("contact_id","");
        
        localStorage.removeItem("pincodes");
        localStorage.removeItem("del_status");
        dispatch(changePincode(""));
        dispatch(changeDeliveyStatus(""));


        dispatch(removeAllItem());
        dispatch(removeAllDummyCart());
        dispatch(changeAuthLabel("Sign in"));
        onClose();
        {/* /ecommerce Remove  */}
        navigate("/category")
    }
    const handleGoToCart = () =>{
        onClose();
        navigate("/cart");
    }
  return (
    <>
        <Card >
            <CardBody>
                <Center>
                    <Heading size='md'>Are you sure ?</Heading>
                </Center>
                {cart.length > 0 &&
                    <Center>
                        <Text>Your cart has some item.</Text>
                    </Center>
                }
                <Center mt={10}>
                    {cart.length > 0 &&
                        <Button colorScheme='teal' size='sm' onClick={handleGoToCart}>
                            Go to Cart
                        </Button>
                    }
                    <Button ml={2}  colorScheme='red' size='sm' onClick={handleLogout}>
                        Sign out
                    </Button>
                    <Button ml={2}  colorScheme='yellow' size='sm' onClick={onClose} variant='outline'>
                        Cancel
                    </Button>
                </Center>
            </CardBody>
        </Card>
    </>
  )
}
