import React from "react";
import FieldComponent from "./FieldComponent";
import {
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,Box,Container,Heading,FormControl,
    FormLabel,
    FormErrorMessage,
    FormHelperText,
  } from '@chakra-ui/react'

function ContactCustomfield({customfield,regi,setValue}){
    const data = Object.values(customfield);
    return(
        <div>
            {data.map((custom,index) => (
                <>
                    <Box >
                        {/* <Heading as='h4' size='md' mb={4}>
                            {custom.Name}
                        </Heading> */}
                        {Object.values(custom.Fields).map((field,index1) => (
                            <FormControl key={index1} my={5}>
                                <FormLabel>{field.Name}</FormLabel>
                                <FieldComponent  setValue={setValue} groupname={custom.ID} field_id={field.ID} type={field.Type} options={field.Options} register={regi} preFillMulti = {field.Type == 12 ? field.PreFilled : "[]"} PreFilled={field.PreFilled}/>
                            </FormControl>
                        ))}
                        
                    </Box>
                </>
                ))}
        </div>
    )
}
export default ContactCustomfield;